<!-- 用户个人信息更改 -->
<template>
  <el-dialog
    class="dialog"
    :visible.sync="showUpdate"
    width="20rem"
    center
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <span>用户信息修改</span>
    </span>
    <el-upload
      class="avatar-uploader"
      :headers="mytoken"
      :data="{ folder: 'avatar' }"
      action="api/cos/upload"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :on-error="handleAvatarError"
      :before-upload="beforeAvatarUpload"
    >
      <p
        style="
          font-size: 0.6rem;
          display: block;
          width: 100%;
          text-align: center;
          color: #b0b0b0;
        "
      >
        点击修改头像
      </p>

      <img
        slot="trigger"
        :src="
          cookieUserInfo.avatar
            ? cookieUserInfo.avatar
            : 'https://linggan-1316937351.cos.ap-shanghai.myqcloud.com/user.png'
        "
        class="avatar"
        style="
          width: 3rem;
          height: 3rem;
          padding: 3px;
          border: 1px dashed #d9d9d9;
        "
      />
    </el-upload>
    <el-form
      class="formbody"
      label-position="left"
      label-width="0px"
      :model="cookieUserInfo"
      ref="changeUserInfo"
    >
      <el-form-item label="" prop="">
        <el-input
          v-model.trim="cookieUserInfo.name"
          placeholder="用户昵称"
          size="mini"
        >
          <i slot="prefix" class="el-input__icon el-icon-lock"></i
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          size="mini"
          v-model="cookieUserInfo.email"
          placeholder="用户邮箱"
          style="flex: 1"
        >
          <i slot="prefix" class="el-input__icon el-icon-lock"></i
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="submitForm('changeUserInfo')"
        size="mini"
        >确 定 修 改</el-button
      >
    </span>
  </el-dialog>
</template>
  
<script>
import store from "@/store";
import Clipboard from "clipboard";
import { usergetpersonal, update } from "@/api/user";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      showUpdate: false,
      // 修改的用户信息
      cookieUserInfo: {
        account: "",
        mobile: "",
      },
    };
  },
  created() {},
  mounted() {},
  watch: {
    userInfo: {
      handler(newvalue) {
        this.cookieUserInfo = Object.assign({}, newvalue);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    mytoken() {
      return {
        "x-auth-token": localStorage.getItem("token"),
      };
    },
  },
  methods: {
    // 上传头像成功钩子
    handleAvatarSuccess(res, file) {
      this.cookieUserInfo.avatar = res.data;
    },
    handleAvatarError(err, file) {
      this.$message({
        message: JSON.parse(err.message).message,
        type: "warning",
        center: true,
      });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
    // 用户信息修改
    async changeuserInfo() {
      await update(this.cookieUserInfo).then((res) => {
        this.showUpdate = false;
        this.getuserInfo();
      });
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === "changeUserInfo") {
            this.changeuserInfo();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 更新目前用户信息
    async getuserInfo() {
      await usergetpersonal().then((res) => {
        store.commit("user/CHANGE_userInfo", res.data);
      });
    },
  },
};
</script>
<style >
.el-dialog--center .el-dialog__body {
  text-align: center;
}
</style>