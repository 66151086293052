<!--
 * @Author: dingguowei
 * @Date: 2023-06-30 14:36:05
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-30 16:21:56
-->
<template>
  <div class="setting">
    <el-card class="box-card">
      <div v-for="(item, index) in settingData" class="item" :key="index">
        <div class="text">
          <p>{{ item.name }}</p>
          <p style="font-size: 0.5rem; font-weight: 500">{{ item.desc }}</p>
        </div>
        <div class="handle" @click="allFunction(item)">
          <el-image
            v-if="item.name === '用户信息修改'"
            :src="
              userInfo.avatar
                ? userInfo.avatar
                : 'https://linggan-1316937351.cos.ap-shanghai.myqcloud.com/user.png'
            "
            alt=""
          />
          <span v-else :style="{ color: item.handelColor }">{{
            item.handel
          }}</span>
        </div>
      </div>
    </el-card>
    <updateInfo ref="updateInfo" />
    <updatepassword ref="updatepassword" />
    <invitation-dia ref="invitationDia" />
  </div>
</template>

<script>
import moment from "moment";
import store from "@/store";
import { mapState } from "vuex";

import updateInfo from "@/components/personalInfo/updateInfo.vue";
import updatepassword from "@/components/personalInfo/updatepassword.vue";
import invitationDia from "@/components/personalInfo/invitationDia.vue";
export default {
  components: {
    updateInfo,
    updatepassword,
    invitationDia,
  },
  data() {
    return {
      settingData: [
        {
          name: "用户信息修改",
          fun: "updateUser",
          avatar:
            "https://linggan-1316937351.cos.ap-shanghai.myqcloud.com/user.png",
        },
        {
          name: "当前版本号：" + moment(new Date()).format("YYYYMMDD"),
          handel: "暂无更新",
          handelColor: "rgb(189 189 189)",
        },
        {
          name: "手机号",
          handel: store.state.user.userInfo.mobile,
          handelColor: "rgb(189 189 189)",
        },
        {
          name: "访问密码",
          handel: "点击修改",
          handelColor: "#acacf2",
          fun: "changepassword",
        },
        {
          name: "邀请码",
          handel: "点击查看",
          handelColor: "#acacf2",
          fun: "getinvitation",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  watch: {
    userInfo: {
      handler() {},
      immediate: true,
      deep: true,
    },
  },
  computed: {
    userInfo() {
      return store.state.user.userInfo;
    },
  },
  methods: {
    // 集成方法
    allFunction(item) {
      if (item.fun) {
        this[item.fun]();
      }
    },
    updateUser() {
      this.$refs.updateInfo.showUpdate = true;
    },
    changepassword() {
      this.$refs.updatepassword.showPasswordDia = true;
    },
    getinvitation() {
      this.$refs.invitationDia.showinvitation = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";

.setting {
  padding: 1rem 2rem;
  box-sizing: border-box;
}
::v-deep .el-card {
  height: 100%;
  border-radius: 20px;
}
.box-card {
  .item {
    padding: 0rem 1rem;
    display: flex;
    border-bottom: 1px solid rgb(232, 232, 232);
    .text {
      cursor: pointer;
      height: 3.5rem;
      font-size: 0.82rem;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .handle {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: auto;
      font-size: 0.8rem;
      & .el-image {
        width: 2rem;
        height: 2rem;
        padding: 4px;
        border-radius: 4px;
        border: 1px solid #e8e8e8;
      }
    }
  }
  & :last-child {
    border: none;
  }
}
</style>