<!-- 修改密码 -->
<template>
  <el-dialog
    class="dialog"
    :visible.sync="showPasswordDia"
    width="20rem"
    center
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <span>修改密码</span>
    </span>
    <el-form
      class="formbody"
      label-position="left"
      label-width="0px"
      :model="changePassword"
      :rules="passwordRules"
      ref="changepassword"
    >
      <el-form-item label="" prop="oldPassword">
        <el-input
          v-model.trim="changePassword.oldPassword"
          placeholder="旧密码"
        >
          <i slot="prefix" class="el-input__icon el-icon-lock"></i
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="newPassword">
        <el-input
          v-model.trim="changePassword.newPassword"
          type="password"
          placeholder="新密码"
          style="flex: 1"
        >
          <i slot="prefix" class="el-input__icon el-icon-lock"></i
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="confrimPassword">
        <el-input
          v-model.trim="changePassword.confrimPassword"
          type="password"
          placeholder="确认新密码"
          style="flex: 1"
        >
          <i slot="prefix" class="el-input__icon el-icon-lock"></i
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="submitForm('changepassword')"
        size="mini"
        >确 定 修 改</el-button
      >
    </span>
  </el-dialog>
</template>
  
  <script>
import store from "@/store";
import Clipboard from "clipboard";
import { resetpassword } from "@/api/user";
export default {
  props: {},
  components: {},
  data() {
    var confrimPassword = (rule, value, callback) => {
      if (value === "" || value === undefined) {
        callback(new Error("请再次输入密码"));
      } else {
        if (value === this.changePassword.newPassword) {
          callback();
        } else {
          callback(new Error("两次密码不一致"));
        }
      }
    };
    return {
      showPasswordDia: false, //展示修改密码弹窗
      //修改的新密码
      changePassword: {
        oldPassword: "",
        newPassword: "",
        confrimPassword: "",
      },

      // 修改密码验证规则
      passwordRules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          {
            min: 6,
            max: 10,
            message: "长度在 6 到 10 个字符",
            trigger: "blur",
          },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 10,
            message: "长度在 6 到 10 个字符",
            trigger: "blur",
          },
        ],
        confrimPassword: [{ validator: confrimPassword, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === "changepassword") {
            this.changenewpassWord();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //   修改密码
    async changenewpassWord() {
      await resetpassword({
        newPassword: this.changePassword.newPassword,
        oldPassword: this.changePassword.oldPassword,
        surePassword: this.changePassword.confrimPassword,
      }).then((res) => {
        if (res.code === 1000) {
          this.$message({
            message: "成功修改密码",
            type: "success",
            center: true,
          });
          this.showPasswordDia = false;
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
            center: true,
          });
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
</style>