<!--
 * @Author: dingguowei
 * @Date: 2023-06-30 16:21:12
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-30 18:21:02
-->
<!-- 邀请码弹窗 -->
<template>
  <el-dialog
    class="dialog"
    :visible.sync="showinvitation"
    center
    :close-on-click-modal="false"
  >
    <span slot="title" class="dialog-title">
      <span>邀请</span>
    </span>
    <div class="invitation">
      <div class="left">
        <!-- <p>{{ invitationrules.name }}{{ invitationrules.promptTimes }}次</p>
        <p>已邀人数：{{ invitationData.total }}人</p> -->
        <!-- <p>对方将获得 元作为初始资金</p> -->
        <p
          style="
            font-size: 1rem;
            line-height: 1.6rem;
            margin-top: 10px;
            font-weight: 600;
          "
        >
          您已获得：{{ invitationData.total }}次
        </p>
        <div class="mycode" style="text-align: center; font-size: 0.9rem">
          <p>邀请码</p>
          <span
            style="
              display: block;
              border: 1px dashed #ccc;
              padding: 0.6rem 1rem;
              margin-top: 10px;
              border-radius: 1rem;
              background-color: #cccccc;
              color: #3580ff;
            "
            >{{ userInfo.shareCode }}</span
          >
          <el-button
            class="copy"
            style="margin-top: 20px; background-color: #3580ff; color: white"
            :data-clipboard-text="userInfo.shareCode"
            @click="copymsg()"
            >复制邀请码</el-button
          >
        </div>
      </div>
      <div class="right">
        <el-table
          empty-text="暂未邀请好友"
          :data="invitationData.data"
          :key="tableKey"
          stripe
          size="mini"
          style="width: 100%"
        >
          <el-table-column align="center" label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column
            align="center"
            label="邀请用户"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.userAccount }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="获得返利"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.promptTimes }}次
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          small
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="invitationData.pageNo"
          :total="invitationData.total"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
import store from "@/store";
import Clipboard from "clipboard";
import { mapState } from "vuex";
import { getinvitation, getinvitationrules } from "@/api/user";
import { userInfo } from "os";
export default {
  components: {},
  data() {
    return {
      showinvitation: false,
      tableKey: true,
      invitationData: {
        total: 0,
        pageSize: 10,
        pageNo: 1,
        data: [],
      },
      invitationrules: {
        name: null,
      },
    };
  },
  created() {},
  mounted() {},
  watch: {
    showinvitation: {
      handler(newvalue) {
        if (newvalue == true) {
          this.getinvitation();
          this.getinvitationrules();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  methods: {
    // 获取邀请记录
    async getinvitation() {
      await getinvitation({
        pageNo: 1,
        pageSize: 10,
      }).then((res) => {
        this.invitationData.total = res.data.total;
        this.invitationData.data = res.data.records;
        this.tableKey = !this.tableKey;
      });
    },
    //  获取邀请奖励规则
    async getinvitationrules() {
      await getinvitationrules().then((res) => {
        this.invitationrules = res.data[0];
      });
    },
    copymsg(msg) {
      let _this = this;
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", function (e) {
        // _this.$message("复制成功");
        e.clearSelection();
      });
    },
    handleCurrentChange(val) {
      this.getinvitation();
    },
  },
};
</script>
  <style lang="scss" scoped>
.invitation {
  padding-bottom: 20px;
  display: flex;
  height: 20rem;
  font-size: 0.8rem;

  .left {
    padding: 2rem 2rem;
    line-height: 1.2rem;
    flex: 2;

    background-color: #eaf1ff;
    margin: 0 5px;
  }
  .right {
    overflow: auto;
    flex: 3;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    .pagination {
      padding-top: 4px;
      height: 2rem;
      text-align: right;
    }
  }
}
</style>